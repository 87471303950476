import Card from '~/src/components/mui-wrappers/Card'
import { ReactNode } from 'react'
import { PaperProps } from '@mui/material'

interface Props extends PaperProps{
  children: ReactNode,
}

const ContentContainer = ({ children, sx }: Props) => {
  return (
    <Card sx={{
      backgroundColor: 'content.main',
      color: 'content.contrastText',
      ...sx
    }}>
      {children}
    </Card>
  )
}

export default ContentContainer
